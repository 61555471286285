import { gql } from '@apollo/client'

import { FilterQueryVariables } from '../../types/filters'
import { PublicCalculateReturnDate, PublicListing, ReturnDateAndTimeSlots } from '../../types/listing'
import { client } from '../apollo-client'

export const PUBLIC_LISTING = gql`
  query PublicListing($id: Int!) {
    publicListing(id: $id) {
      id
      createdAt
      updatedAt
      name
      cycle
      status
      minimumAge
      consecutiveBookings
      pickUpDates
      addons {
        id
        name
        description
        price
      }
      fees {
        id
        name
        description
        price
      }
      dealership {
        name
        address {
          city
          postal
          street
        }
      }
      proration
      price
      customer_price
      prices {
        id
        createdAt
        updatedAt
        amount
      }
      vehicleModelVariant {
        id
        createdAt
        updatedAt
        name
        engine
        fuelType
        fuelConsumption
        co2Wltp
        euroNcap
        airbags
        tireSize
        transmissionType
        horsepower
        greenTax6Mth
        seats
        equipment {
          id
          createdAt
          updatedAt
          name
        }
        type
        vehicleModel {
          id
          createdAt
          updatedAt
          name
          vehicleMake {
            id
            createdAt
            updatedAt
            name
          }
        }
      }
    }
  }
`

export const PUBLIC_LISTING_WITH_AVAILABLE_DATES = gql`
  query PublicListing($id: Int!, $from: Date!, $to: Date!) {
    publicListing(id: $id) {
      id
      dealership {
        name
        address {
          city
          postal
          street
        }
        openDates(from: $from, to: $to)
      }
    }
  }
`

export const PUBLIC_CALCULATED_RETURN_DATE = gql`
  query PublicCalculateReturnDate($listingId: Int!, $pickUpAt: Date!, $desiredTotalDays: Int!) {
    publicCalculateReturnDate(listingId: $listingId, pickUpAt: $pickUpAt, desiredTotalDays: $desiredTotalDays) {
      daysDeducted
      daysTotal
      pickUpAt
      priceDeducted
      returnAt
    }
  }
`

export const PUBLIC_LISTING_WITH_AVAILABLE_TIME_SLOTS = gql`
  query PublicListing($id: Int!, $date: Date!) {
    publicListing(id: $id) {
      id
      pickUpDates
      dealership {
        name
        address {
          city
          postal
          street
        }
        timeSlotsAvailable(date: $date)
      }
    }
  }
`

export const PUBLIC_LISTINGS = gql`
  query PublicListings(
    $priceMax: Int
    $priceMin: Int
    $seats: [Int]
    $fuelTypes: [String]
    $vehicleMakes: [String]
    $vehicleTypes: [String]
    $equipment: [Int]
    $transmissionTypes: [String]
    $statusses: [String!]
  ) {
    publicListings(
      priceMax: $priceMax
      priceMin: $priceMin
      seats: $seats
      fuelTypes: $fuelTypes
      vehicleMakes: $vehicleMakes
      vehicleTypes: $vehicleTypes
      equipment: $equipment
      transmissionTypes: $transmissionTypes
      statusses: $statusses
    ) {
      id
      createdAt
      updatedAt
      name
      cycle
      status
      consecutiveBookings
      dealership {
        name
        address {
          city
          postal
          street
        }
      }
      proration
      price
      customer_price
      prices {
        id
        createdAt
        updatedAt
        amount
      }
      vehicleModelVariant {
        id
        createdAt
        updatedAt
        name
        engine
        fuelType
        fuelConsumption
        co2Wltp
        euroNcap
        airbags
        tireSize
        transmissionType
        greenTax6Mth
        seats
        equipment {
          id
          createdAt
          updatedAt
          name
        }
        type
        vehicleModel {
          id
          createdAt
          updatedAt
          name
          vehicleMake {
            id
            createdAt
            updatedAt
            name
          }
        }
      }
    }
  }
`

export const fetchPublicListings = async (sanityListings: any[]) => {
  const validatedListings = await Promise.allSettled(
    sanityListings.map(async offer => {
      const { data } = await client.query({
        query: PUBLIC_LISTING,
        variables: {
          id: offer.listing,
        },
        fetchPolicy: 'no-cache',
      })

      return {
        ...offer,
        data: data?.publicListing,
      }
    })
  )

  const filtered = (validatedListings.filter(offer => offer.status === 'fulfilled') as PromiseFulfilledResult<any>[])
    .map(el => el.value)
    .filter(el => el.data?.pickUpDates.length > 0)

  return filtered
}

export const fetchPublicListingsWithFilter = async (sanityListings: any[], filters?: FilterQueryVariables) => {
  const { data } = await client.query<{ publicListings: any[] }>({
    query: PUBLIC_LISTINGS,
    variables: filters,
    fetchPolicy: 'no-cache',
  })

  const getPending = filters?.statusses?.includes('PENDING') || false

  const combinedListings = []
  for (const sanityListing of sanityListings) {
    const backendListing = data.publicListings.find(listing => {
      return listing.id === sanityListing.listing
    })

    if (!backendListing) continue

    combinedListings.push({
      ...sanityListing,
      data: backendListing,
    })
  }

  //const combinedListings = sanityListings
  //  .map(el => {
  //    const backendListing = data.publicListings.find(listing => {
  //      return listing.id === el.listing
  //    })

  //    return {
  //      ...el,
  //      data: backendListing,
  //    }
  //  })
  //  //.filter(el => (getPending ? el.data?.status === 'PENDING' : el.data?.pickUpDates.length > 0))
  return combinedListings
}

export const fetchAvailableReturnDateAndTimeSlots = async (variables: any): Promise<ReturnDateAndTimeSlots> => {
  const { data: returnData } = await client.query<PublicCalculateReturnDate>({
    query: PUBLIC_CALCULATED_RETURN_DATE,
    variables,
    fetchPolicy: 'no-cache',
  })

  const { data: timeSlots } = await client.query<PublicListing>({
    query: PUBLIC_LISTING_WITH_AVAILABLE_TIME_SLOTS,
    variables: { id: variables.listingId, date: returnData.publicCalculateReturnDate.returnAt },
    fetchPolicy: 'no-cache',
  })

  return {
    returnData,
    timeSlots: timeSlots.publicListing.dealership.timeSlotsAvailable,
  }
}
