import { gql } from '@apollo/client'

const USER_CREATE_RESERVATION = gql`
  mutation UserCreateReservation(
    $customerId: ID
    $listingId: Int!
    $pickUpAt: Date!
    $returnAt: Date!
    $paymentMethodId: Int
    $questionLivingSituation: String!
    $questionNoOutstandingDebt: Boolean!
    $questionValidLicense: Boolean!
    $questionLivesInCountry: Boolean!
    $questionIsCitizen: Boolean!
    $addons: [Int!]
    $fees: [Int!]
    $deliveryAddress: DeliveryAddressInput
    $additionalDriver: AdditionalDriverInput
    $linkedFromId: Int
  ) {
    userCreateReservation(
      customerId: $customerId
      listingId: $listingId
      pickUpAt: $pickUpAt
      returnAt: $returnAt
      paymentMethodId: $paymentMethodId
      questionLivingSituation: $questionLivingSituation
      questionNoOutstandingDebt: $questionNoOutstandingDebt
      questionValidLicense: $questionValidLicense
      questionLivesInCountry: $questionLivesInCountry
      questionIsCitizen: $questionIsCitizen
      addons: $addons
      fees: $fees
      deliveryAddress: $deliveryAddress
      additionalDriver: $additionalDriver
      linkedFromId: $linkedFromId
    ) {
      id
    }
  }
`

const USER_CREATE_ADDITIONAL_DRIVER = gql`
  mutation UserCreateAdditionalDriver(
    $firstName: String!
    $lastName: String!
    $email: String!
    $licenseNumber: String!
    $licenseExpiry: Date
  ) {
    userCreateAdditionalDriver(
      firstName: $firstName
      lastName: $lastName
      email: $email
      licenseNumber: $licenseNumber
      licenseExpiry: $licenseExpiry
    ) {
      id
    }
  }
`

const USER_RESERVATION_SIGN = gql`
  mutation UserReservationSign($reservationId: Int!) {
    userReservationSign(reservationId: $reservationId) {
      id
    }
  }
`

const USER_RESERVATION_RETRY_PREPERIOD_PAYMENT = gql`
  mutation UserReservationRetryPreperiodPayment($reservationId: Int!, $paymentMethodId: Int) {
    userReservationRetryPreperiodPayment(reservationId: $reservationId, paymentMethodId: $paymentMethodId) {
      id
    }
  }
`

const USER_RESERVATION_RETRY_VERIFICATION = gql`
  mutation UserReservationRetryVerification($reservationId: Int!, $customerId: ID) {
    userReservationRetryVerification(reservationId: $reservationId, customerId: $customerId) {
      id
      status
    }
  }
`

const USER_RESERVATION_MOVE_PICK_UP = gql`
  mutation UserReservationMovePickUp($id: Int!, $pickUpAt: Date!) {
    userReservationMovePickUp(id: $id, pickUpAt: $pickUpAt)
  }
`

const USER_RESERVATION_MOVE_RETURN = gql`
  mutation UserReservationMoveReturn($id: Int!, $returnAt: Date!) {
    userReservationMoveReturn(id: $id, returnAt: $returnAt)
  }
`

export {
  USER_CREATE_RESERVATION,
  USER_CREATE_ADDITIONAL_DRIVER,
  USER_RESERVATION_SIGN,
  USER_RESERVATION_RETRY_PREPERIOD_PAYMENT,
  USER_RESERVATION_RETRY_VERIFICATION,
  USER_RESERVATION_MOVE_PICK_UP,
  USER_RESERVATION_MOVE_RETURN,
}
