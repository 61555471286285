import { gql } from '@apollo/client'

export const USER_RESERVATIONS = gql`
  query UserReservations {
    userReservations {
      id
      createdAt
      updatedAt
      pickUpAt
      returnAt
      pickedUpAt
      returnedAt
      status
      addons {
        id
        description
        name
        price
      }
      fees {
        id
        description
        name
        price
      }
      listing {
        id
        name
        dealership {
          timeSlots
        }
      }
      files {
        id
        createdAt
        updatedAt
        type
        documentType
        fileName
        filePath
        readUrl
        signingProcessorId
        signingProcessorUrl
        signingProcessorStatus
      }
      pickUpDates
      returnDates
      verifications {
        id
        createdAt
        updatedAt
        status
        message
      }
      customer {
        id
        createdAt
        updatedAt
        firstName
        lastName
        birthdate
        licenseNumber
        licenseExpiry
        address {
          street
          floor
          city
          postal
        }
        phone
      }
      paymentMethod {
        id
        createdAt
        updatedAt
        last4
        brand
        isDefault
      }
      invoices {
        id
        createdAt
        updatedAt
        periodStart
        periodEnd
        dateDue
        dateSucceeded
        dateAuthorized
        status
        type
        amount

        payments {
          dateSucceeded
          dateDue
          dateAuthorized
          status
        }

        files {
          id
          createdAt
          updatedAt
          type
          documentType
          fileName
          filePath
          readUrl
        }
      }
      linkedFrom {
        id
        returnAt
      }
      vehicle {
        vin
        color
        plate
      }
      pickUpDealership {
        name
        address {
          city
          postal
          street
        }
      }
      price
      returnDealership {
        name
        address {
          city
          postal
          street
        }
      }
    }
  }
`

export const USER_RESERVATIONS_VERIFICATION = gql`
  query UserReservations {
    userReservations {
      id
      createdAt
      updatedAt
      status
      files {
        id
        createdAt
        updatedAt
        type
        documentType
        fileName
        filePath
        readUrl
        signingProcessorId
        signingProcessorUrl
        signingProcessorStatus
      }
      verifications {
        id
        createdAt
        updatedAt
        status
        message
      }
      invoices {
        id
        createdAt
        updatedAt
        periodStart
        periodEnd
        dateDue
        dateSucceeded
        dateAuthorized
        status
        type
        amount

        payments {
          dateSucceeded
          dateDue
          dateAuthorized
          status
        }
      }
    }
  }
`

export const USER_RESERVATIONS_SIGNING_URL = gql`
  query UserReservations {
    userReservations {
      id
      files {
        id
        type
        documentType
        signingProcessorFulfillmentUrl
      }
    }
  }
`
