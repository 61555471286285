import 'swiper/css'
import 'swiper/css/navigation'
import 'react-tooltip/dist/react-tooltip.css'

import '../styles/index.css'

import { ApolloProvider } from '@apollo/client/react'
import * as snippet from '@segment/snippet'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { NextPage } from 'next'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import Script from 'next/script'
import * as React from 'react'

import Layout from '../components/Layouts/Layout'
import { client } from '../graphql/apollo-client'
import { trackPage } from '../tracking'

gsap.registerPlugin(ScrollTrigger)

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter()
  if (router.pathname === '/be') return (
    <div className="w-full h-[100vh]">
      <Component />
    </div>
  )
  
  const getLayout = Component.getLayout ?? (page => page)

  const loadSegment = () => {
    const options = {
      apiKey: process.env.NEXT_PUBLIC_SEGMENT_KEY,
      // page: true,
    }
    if (process.env.NEXT_PUBLIC_NODE_ENV) {
      return snippet.max(options)
    } else {
      return snippet.min(options)
    }
  }

  React.useEffect(() => {
    if (router.asPath) trackPage()
  }, [router.asPath])
  
  return (
    <ApolloProvider client={client}>
      <noscript dangerouslySetInnerHTML={{
        __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TMNNKKB"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>`
      }} />

      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-TMNNKKB');`,
        }}
      ></script>

      <Script dangerouslySetInnerHTML={{ __html: loadSegment() }} id="segmentScript" />

      <Layout>{getLayout(<Component {...pageProps} />)}</Layout>
    </ApolloProvider>
  )
}

export default MyApp
